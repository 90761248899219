import { Injectable } from '@angular/core';
import { CrmDictionary } from 'common-module/core/types';
import { CrmEndpoint, CrmEndpointDecorator } from 'common-module/endpoint';
import { crmSafeListByIDs } from 'common-module/list';
import { DateTime } from 'luxon';
import { map } from 'rxjs';

import { withDefaultRecordsSort } from '../../record/with-default-records-sort';

import { CreatePatientAdmissionDto } from './create-patient-admission.dto';
import { PatientAdmissionModel } from './patient-admission.model';
import { UpdatePatientAdmissionDto } from './update-patient-admission.dto';

@Injectable({ providedIn: 'root' })
export class PatientsAdmissionsApiService {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'patients/records/admissions',
  })
  protected endpoint!: CrmEndpoint<PatientAdmissionModel>;

  list(params: CrmDictionary) {
    return this.endpoint.list({ params: withDefaultRecordsSort(params) });
  }

  listAll(params: CrmDictionary) {
    return this.endpoint.listAll({ params: withDefaultRecordsSort(params) });
  }

  listSafe(ids: string[]) {
    return crmSafeListByIDs(
      (params) => this.list(params).pipe(map(({ data }) => data)),
      ids,
      20,
    );
  }

  listAllWithin60Days(params: CrmDictionary) {
    return this.listAll({
      ...params,
      'admissionDate[$gte]': DateTime.now().startOf('day').minus({ day: 60 }),
      'dischargeDate[$exists]': true,
      '$sort[admissionDate]': '-1',
    });
  }

  get(id: string) {
    return this.endpoint.read(id);
  }

  create(body: CreatePatientAdmissionDto) {
    return this.endpoint.create(body);
  }

  update(id: string, body: UpdatePatientAdmissionDto) {
    return this.endpoint.update(id, body);
  }
}
